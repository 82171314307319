:root {
    --text-h1: 6rem;
    --text-h2: 3.75rem;
    --text-h3: 3rem;
    --text-h4: 2rem;
    --text-h5: 1.75rem;
    --text-md: 2rem;
    --text-overline: 1.5rem;
    --text-info: 1rem;
}

body {
    font-family: system-ui, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
    font-size: var(--text-h1);
}

h2,
.h2 {
    font-size: var(--text-h2);
}

h3,
.h3 {
    font-size: var(--text-h3);
}

h4 {
    font-size: var(--text-h4);
}

h5 {
    font-size: var(--text-h5);
}

.overline {
    font-size: var(--text-overline);
    font-family: Verdana, system-ui, sans-serif;
    font-weight: normal;
    opacity: 0.8;
}

.info {
    font-size: var(--text-info);
    font-weight:800;
    font-family: monospace;
}

.subtitle-1 {
    font-size: 3rem;
}

.subtitle-2 {
    font-size: 2rem;
    font-weight: lighter;
}

body {
    font-size: 1rem;
}


/* Rules for sizing the icon. */
.iconify {
    color: var(--text1);
    min-width: 1em;
}

* {
    z-index: 1;
}

span.underline {
    position: relative;
    display: inline-block;

    &:before {
        content: "";
        bottom: 0;
        right: 0.5ch;
        left: 0;
        top: 90%;
        z-index: -1;
        opacity: 0.7;
        position: absolute;
        background: linear-gradient(90deg, var(--accent) 75%, rgba(0, 0, 0, 0) 100%);
    }
}

span.decender {
    position: relative;
    display: inline-block;

    &:before {
        content: "";
        bottom: 0;
        right: 0;
        left: 0;
        top: 90%;
        z-index: -1;
        opacity: 1;
        position: absolute;
        background: linear-gradient(90deg, var(--surface1), var(--surface1));
    }
}
