:root {
  --text-h1: 6rem;
  --text-h2: 3.75rem;
  --text-h3: 3rem;
  --text-h4: 2rem;
  --text-h5: 1.75rem;
  --text-md: 2rem;
  --text-overline: 1.5rem;
  --text-info: 1rem;
}

body {
  font-family: system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
  font-size: var(--text-h1);
}

h2,
.h2 {
  font-size: var(--text-h2);
}

h3,
.h3 {
  font-size: var(--text-h3);
}

h4 {
  font-size: var(--text-h4);
}

h5 {
  font-size: var(--text-h5);
}

.overline {
  font-size: var(--text-overline);
  font-family: Verdana, system-ui, sans-serif;
  font-weight: normal;
  opacity: 0.8;
}

.info {
  font-size: var(--text-info);
  font-weight: 800;
  font-family: monospace;
}

.subtitle-1 {
  font-size: 3rem;
}

.subtitle-2 {
  font-size: 2rem;
  font-weight: lighter;
}

body {
  font-size: 1rem;
}

/* Rules for sizing the icon. */
.iconify {
  color: var(--text1);
  min-width: 1em;
}

* {
  z-index: 1;
}

span.underline {
  position: relative;
  display: inline-block;
}
span.underline:before {
  content: "";
  bottom: 0;
  right: 0.5ch;
  left: 0;
  top: 90%;
  z-index: -1;
  opacity: 0.7;
  position: absolute;
  background: linear-gradient(90deg, var(--accent) 75%, rgba(0, 0, 0, 0) 100%);
}

span.decender {
  position: relative;
  display: inline-block;
}
span.decender:before {
  content: "";
  bottom: 0;
  right: 0;
  left: 0;
  top: 90%;
  z-index: -1;
  opacity: 1;
  position: absolute;
  background: linear-gradient(90deg, var(--surface1), var(--surface1));
}
:root {
  --space-sm: 0.5rem;
  --space-xs: 0.25rem;
  --space-md: 1rem;
  --space-lg: 3rem;
  --space-xl: 9rem;
  --space-xxl: 27rem;
}
@keyframes pulse {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(2.2);
  }
}
body {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}
main {
  padding-top: var(--space-md);
  padding-left: var(--space-md);
  padding-bottom: var(--space-xl);
}
footer {
  padding-left: var(--space-md);
  padding: var(--space-md);
  color: var(--text2);
}
footer * {
  color: inherit;
}
footer ul {
  display: flex;
  column-gap: var(--space-lg);
}
#contact {
  display: flex;
}
#contact ul {
  display: flex;
  column-gap: var(--space-lg);
  flex-flow: row wrap;
}
@media screen and (max-width: 600px) {
  #contact ul {
    flex-flow: column nowrap;
    row-gap: var(--space-md);
  }
}
@media screen and (min-width: 601px) and (max-width: 1200px) {
  #contact ul {
    flex-flow: column nowrap;
    row-gap: var(--space-md);
  }
}
#contact li {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
#contact li > span {
  display: flex;
  flex-wrap: nowrap;
  padding: var(--space-xs);
  align-items: center;
  position: relative;
}
#contact li > span:after {
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s background-position;
  background-position: 100% 0%;
  background-size: 250%;
  background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 45%, var(--accent) 55%, var(--accent) 100%);
}
#contact li > span:hover:after {
  background-position: 0% 0%;
}
#contact li > span > a {
  display: inline-flex;
  align-items: center;
  column-gap: var(--space-sm);
}
#contact a {
  color: var(--text2) !important;
  text-decoration: none !important;
}
.lawery {
  background-color: var(--surface1);
  color: var(--text1);
  padding: var(--space-md);
}
.lawery .back-wrap {
  position: fixed !important;
  top: var(--space-md);
  left: var(--space-md);
  padding: var(--space-sm);
  z-index: 2;
  opacity: 0.7;
  background-color: var(--surface3);
}
.lawery .back-wrap:hover {
  opacity: 1;
}
.lawery a {
  color: var(--text2) !important;
  position: relative;
}
.lawery a:after {
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s background-position;
  background-position: 100% 0%;
  background-size: 250%;
  background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 45%, var(--accent) 55%, var(--accent) 100%);
}
.lawery a:hover:after {
  background-position: 0% 0%;
}
.lawery .back {
  color: var(--text2);
}
.lawery section {
  font-size: 2rem;
  margin: auto;
  max-width: min(50ch, 100%);
}
.lawery h1 {
  font-size: 3rem;
  margin: 1em 0;
}
.lawery h2 {
  font-size: 2rem;
  margin: 1em 0;
}
.lawery ol {
  margin-left: 2em;
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
  list-style-type: upper-roman;
}
.lawery h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1em 0;
}
.lawery h4 {
  font-size: 1.5rem;
  margin: 1em 0;
}
html {
  background-color: var(--surface1);
}
main {
  display: grid;
  flex: 1 0 0;
  background-color: var(--surface1);
  grid-template-columns: 1fr fit-content(35rem);
  grid-template-rows: 1fr;
}
main > * {
  min-width: 0px !important;
}
#hero {
  color: var(--text1);
  display: grid;
  height: 100%;
  place-items: center start;
}
#hero > div {
  max-width: 80ch;
}
#hero h1 {
  margin-bottom: var(--space-xs);
  font-weight: lighter;
  margin-left: 3px;
}
#hero .icon-row {
  margin-top: var(--space-md);
  display: flex;
  column-gap: var(--space-md);
  font-size: 2rem;
}
#left-head {
  display: flex;
  flex-flow: column nowrap;
  row-gap: var(--space-lg);
}
#header-img {
  grid-row: 1/3;
  --mobile-height: 12rem;
  background-repeat: no-repeat;
  position: relative;
  grid-column: 2/3;
  margin-top: calc(-1 * var(--space-md));
  height: 40rem;
  width: 35rem;
  background-size: cover;
  background-position: 25% 50%;
  border-left: 2px solid var(--accent);
  border-bottom-left-radius: 25rem;
  right: 0;
  background-image: url("../me.a92702f9.webp");
}
@media screen and (min-width: 601px) and (max-width: 1200px) {
  #header-img {
    position: absolute;
    height: var(--mobile-height);
    width: 50%;
    background-position: 25% 50%;
  }
}
@media screen and (max-width: 600px) {
  #header-img {
    position: absolute;
    height: var(--mobile-height);
    width: 50%;
    background-position: 5% -25%;
  }
}
footer {
  background-color: var(--surface1);
}
footer a {
  text-decoration: none;
  position: relative;
}
footer a:after {
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s background-position;
  background-position: 100% 0%;
  background-size: 250%;
  background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 45%, var(--accent) 55%, var(--accent) 100%);
}
footer a:hover:after {
  background-position: 0% 0%;
}
html {
  --hue: 174;
  --saturation: 100%;
  --lightness: 50%;
  --accent: springgreen;
  --brand: hsl(var(--hue) calc(var(--saturation) / 2) var(--lightness));
  --text1: hsl(var(--hue) 15% 85%);
  --text2: hsl(var(--hue) 15% 65%);
  --surface1: hsl(var(--hue) 10% 10%);
  --surface2: hsl(var(--hue) 10% 15%);
  --surface3: hsl(var(--hue) 5% 20%);
  --surface4: hsl(var(--hue) 5% 25%);
  /*!
  Theme: a11y-dark
  Author: @ericwbailey
  Maintainer: @ericwbailey

  Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
  */
  --hljs-backround: #2b2b2b;
  --hljs-color: #f8f8f2;
  --hljs-quote-color: #d4d0ab;
  --hljs-variable-color: #ffa07a;
  --hljs-type-color: #f5ab37;
  --hljs-attribute-color: gold;
  --hljs-symbol-color: #abe338;
  --hljs-title-color: #00e0e0;
  --hljs-keyword-color: #dcc6e0;
}

body {
  --hue: 174;
  --saturation: 100%;
  --lightness: 50%;
  --accent: springgreen;
  --brand: hsl(var(--hue) calc(var(--saturation) / 2) var(--lightness));
  --text1: hsl(var(--hue) 15% 85%);
  --text2: hsl(var(--hue) 15% 65%);
  --surface1: hsl(var(--hue) 10% 10%);
  --surface2: hsl(var(--hue) 10% 15%);
  --surface3: hsl(var(--hue) 5% 20%);
  --surface4: hsl(var(--hue) 5% 25%);
  /*!
  Theme: a11y-dark
  Author: @ericwbailey
  Maintainer: @ericwbailey

  Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
  */
  --hljs-backround: #2b2b2b;
  --hljs-color: #f8f8f2;
  --hljs-quote-color: #d4d0ab;
  --hljs-variable-color: #ffa07a;
  --hljs-type-color: #f5ab37;
  --hljs-attribute-color: gold;
  --hljs-symbol-color: #abe338;
  --hljs-title-color: #00e0e0;
  --hljs-keyword-color: #dcc6e0;
}

@media (prefers-color-scheme: dark) {
  body.theme {
    --hue: 174;
    --saturation: 100%;
    --lightness: 50%;
    --accent: springgreen;
    --brand: hsl(var(--hue) calc(var(--saturation) / 2) var(--lightness));
    --text1: hsl(var(--hue) 15% 85%);
    --text2: hsl(var(--hue) 15% 65%);
    --surface1: hsl(var(--hue) 10% 10%);
    --surface2: hsl(var(--hue) 10% 15%);
    --surface3: hsl(var(--hue) 5% 20%);
    --surface4: hsl(var(--hue) 5% 25%);
    /*!
    Theme: a11y-dark
    Author: @ericwbailey
    Maintainer: @ericwbailey

    Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
    */
    --hljs-backround: #2b2b2b;
    --hljs-color: #f8f8f2;
    --hljs-quote-color: #d4d0ab;
    --hljs-variable-color: #ffa07a;
    --hljs-type-color: #f5ab37;
    --hljs-attribute-color: gold;
    --hljs-symbol-color: #abe338;
    --hljs-title-color: #00e0e0;
    --hljs-keyword-color: #dcc6e0;
  }
}
@media (prefers-color-scheme: light) {
  body.theme {
    --hue: 174;
    --saturation: 100%;
    --lightness: 50%;
    --accent: springgreen;
    --brand: hsl(var(--hue) calc(var(--saturation) / 2) var(--lightness));
    --text1: hsl(var(--hue) 15% 85%);
    --text2: hsl(var(--hue) 15% 65%);
    --surface1: hsl(var(--hue) 10% 10%);
    --surface2: hsl(var(--hue) 10% 15%);
    --surface3: hsl(var(--hue) 5% 20%);
    --surface4: hsl(var(--hue) 5% 25%);
    /*!
    Theme: a11y-dark
    Author: @ericwbailey
    Maintainer: @ericwbailey

    Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
    */
    --hljs-backround: #2b2b2b;
    --hljs-color: #f8f8f2;
    --hljs-quote-color: #d4d0ab;
    --hljs-variable-color: #ffa07a;
    --hljs-type-color: #f5ab37;
    --hljs-attribute-color: gold;
    --hljs-symbol-color: #abe338;
    --hljs-title-color: #00e0e0;
    --hljs-keyword-color: #dcc6e0;
  }
}
body.theme.theme--light {
  --hue: 174;
  --saturation: 100%;
  --lightness: 50%;
  --accent: springgreen;
  --brand: hsl(var(--hue) calc(var(--saturation) / 2) var(--lightness));
  --text1: hsl(var(--hue) 15% 85%);
  --text2: hsl(var(--hue) 15% 65%);
  --surface1: hsl(var(--hue) 10% 10%);
  --surface2: hsl(var(--hue) 10% 15%);
  --surface3: hsl(var(--hue) 5% 20%);
  --surface4: hsl(var(--hue) 5% 25%);
  /*!
  Theme: a11y-dark
  Author: @ericwbailey
  Maintainer: @ericwbailey

  Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
  */
  --hljs-backround: #2b2b2b;
  --hljs-color: #f8f8f2;
  --hljs-quote-color: #d4d0ab;
  --hljs-variable-color: #ffa07a;
  --hljs-type-color: #f5ab37;
  --hljs-attribute-color: gold;
  --hljs-symbol-color: #abe338;
  --hljs-title-color: #00e0e0;
  --hljs-keyword-color: #dcc6e0;
}

body.theme.theme--dark {
  --hue: 174;
  --saturation: 100%;
  --lightness: 50%;
  --accent: springgreen;
  --brand: hsl(var(--hue) calc(var(--saturation) / 2) var(--lightness));
  --text1: hsl(var(--hue) 15% 85%);
  --text2: hsl(var(--hue) 15% 65%);
  --surface1: hsl(var(--hue) 10% 10%);
  --surface2: hsl(var(--hue) 10% 15%);
  --surface3: hsl(var(--hue) 5% 20%);
  --surface4: hsl(var(--hue) 5% 25%);
  /*!
  Theme: a11y-dark
  Author: @ericwbailey
  Maintainer: @ericwbailey

  Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
  */
  --hljs-backround: #2b2b2b;
  --hljs-color: #f8f8f2;
  --hljs-quote-color: #d4d0ab;
  --hljs-variable-color: #ffa07a;
  --hljs-type-color: #f5ab37;
  --hljs-attribute-color: gold;
  --hljs-symbol-color: #abe338;
  --hljs-title-color: #00e0e0;
  --hljs-keyword-color: #dcc6e0;
}

.material-icons {
  color: var(--text1);
}
html {
  box-sizing: border-box;
  overflow-x: hidden;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
ul {
  list-style-type: none;
}
button:focus-visible {
  outline: thin var(--accent) dashed !important;
  outline-offset: var(--space-sm);
  color: var(--accent);
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
/*# sourceMappingURL=awa-style.67a6061d.css.map */
