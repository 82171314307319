@mixin light-colors {
    --hue: 233;
    --saturation: 100%;
    --lightness: 50%;
    --brand: hsl(var(--hue) var(--saturation) var(--lightness));
    --accent: navy;
    /* crimson */
    --text1: hsl(var(--hue) var(--saturation) 10%);

    --text2: hsl(var(--hue) calc(var(--saturation) / 2) 30%);
    --surface1: hsl(var(--hue) 50% 90%);
    --surface2: hsl(var(--hue) 10% 99%);
    --surface3: hsl(var(--hue) 10% 96%);
    --surface4: hsl(var(--hue) 10% 85%);

    /*!
        Theme: a11y-light
        Author: @ericwbailey
        Maintainer: @ericwbailey
        Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
    */

    --hljs-backround: #fefefe;
    --hljs-color: #545454;
    --hljs-quote-color: #696969;
    --hljs-variable-color: #d91e18;
    --hljs-type-color: #aa5d00;
    --hljs-attribute-color: var(--hljs-type-color);
    --hljs-symbol-color: #green;
    --hljs-title-color: #007faa;
    --hljs-keyword-color: #7928a1;
}

@mixin dark-colors {
    --hue: 174;
    --saturation: 100%;
    --lightness: 50%;

    --accent: springgreen;
    --brand: hsl(var(--hue) calc(var(--saturation) / 2) var(--lightness));
    --text1: hsl(var(--hue) 15% 85%);
    --text2: hsl(var(--hue) 15% 65%);
    --surface1: hsl(var(--hue) 10% 10%);
    --surface2: hsl(var(--hue) 10% 15%);
    --surface3: hsl(var(--hue) 5% 20%);
    --surface4: hsl(var(--hue) 5% 25%);

    /*!
    Theme: a11y-dark
    Author: @ericwbailey
    Maintainer: @ericwbailey
  
    Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
    */
    --hljs-backround: #2b2b2b;
    --hljs-color: #f8f8f2;
    --hljs-quote-color: #d4d0ab;
    --hljs-variable-color: #ffa07a;
    --hljs-type-color: #f5ab37;
    --hljs-attribute-color: gold;
    --hljs-symbol-color: #abe338;
    --hljs-title-color: #00e0e0;
    --hljs-keyword-color: #dcc6e0;
}
html {
    @include dark-colors();
}
body {
    //@include light-colors();
    @include dark-colors();
}

@media (prefers-color-scheme: dark) {
    body.theme {
        @include dark-colors();
    }
}

@media (prefers-color-scheme: light) {
    body.theme {
        //@include light-colors();
        @include dark-colors();
    }
}

body.theme.theme--light {
    //@include light-colors();
    @include dark-colors();
}

body.theme.theme--dark {
    @include dark-colors();
}

.material-icons {
    color: var(--text1);
}