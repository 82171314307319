@use './mixins';

body {
    min-height: 100vh;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
}

main {
    padding-top: var(--space-md);
    padding-left: var(--space-md);
    padding-bottom: var(--space-xl);
}

footer {
    padding-left: var(--space-md);
    padding: var(--space-md);
    color: var(--text2);

    * {
        color: inherit;
    }

    ul {
        display: flex;
        column-gap: var(--space-lg);
    }
}

#contact {
    display: flex;

    ul {
        display: flex;
        column-gap: var(--space-lg);
        flex-flow: row wrap;

        @include mixins.mobile() {
            flex-flow: column nowrap;
            row-gap: var(--space-md);
        }

        @include mixins.tablet() {
            flex-flow: column nowrap;
            row-gap: var(--space-md);
        }
    }

    li {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    li>span {
        display: flex;
        flex-wrap: nowrap;
        padding: var(--space-xs);
        align-items: center;
        @include mixins.hover();

        >a {
            display: inline-flex;
            align-items: center;
            column-gap: var(--space-sm);
        }

    }

    a {
        color: var(--text2) !important;
        text-decoration: none !important;
    }
}

.lawery {
    .back-wrap {
        position: fixed !important;
        top: var(--space-md);
        left: var(--space-md);
        padding: var(--space-sm);
        z-index: 2;
        opacity: 0.7;
        background-color: var(--surface3);

        &:hover {
            opacity: 1;
        }
    }

    a {
        color: var(--text2) !important;
        @include mixins.hover();
    }

    .back {

        color: var(--text2);
    }

    background-color: var(--surface1);
    color: var(--text1);
    padding: var(--space-md);

    section {
        font-size: 2rem;
        margin: auto;
        max-width: min(50ch, 100%);
    }

    h1 {
        font-size: 3rem;
        margin: 1em 0;
    }

    h2 {
        font-size: 2rem;
        margin: 1em 0;
    }

    ol {
        margin-left: 2em;
        margin-top: var(--space-lg);
        margin-bottom: var(--space-lg);
        list-style-type: upper-roman;
    }

    h3 {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 1em 0;
    }

    h4 {
        font-size: 1.5rem;
        margin: 1em 0;
    }
}

html {
    background-color: var(--surface1);
}

main {
    display: grid;
    flex: 1 0 0;
    background-color: var(--surface1);
    grid-template-columns: 1fr fit-content(35rem);
    grid-template-rows: 1fr;

    >* {
        min-width: 0px !important;
    }
}

#hero {
    color: var(--text1);
    display: grid;
    height: 100%;
    place-items: center start;

    >div {
        max-width: 80ch;
    }

    h1 {
        margin-bottom: var(--space-xs);
        font-weight: lighter;
        margin-left: 3px;
    }

    .icon-row {
        margin-top: var(--space-md);
        display: flex;
        column-gap: var(--space-md);
        font-size: 2rem;
    }
}

#left-head {
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--space-lg);
}

#header-img {
    grid-row: 1/3;
    --mobile-height: 12rem;
    background-repeat: no-repeat;

    @include mixins.tablet() {
        position: absolute;
        height: var(--mobile-height);
        width: 50%;
        background-position: 25% 50%;
    }

    @include mixins.mobile() {
        position: absolute;
        height: var(--mobile-height);
        width: 50%;
        background-position: 5% -25%;
    }

    position: relative;
    grid-column: 2/3;
    margin-top: calc(-1 * var(--space-md));
    height: 40rem;
    width: 35rem;
    background-size: cover;
    background-position: 25% 50%;
    border-left: 2px solid var(--accent);
    border-bottom-left-radius: 25rem;
    right: 0;
    background-image: url("../imgs/me.webp")
}


footer {

    a {
        text-decoration: none;
        @include mixins.hover();
    }

    background-color: var(--surface1);

}