@import url('./scss/typography.scss');
@import url('./scss/spacing.scss');
@import url('./scss/blocks.scss');
@import url('./scss/color.scss');


html {
    box-sizing: border-box;
    overflow-x: hidden;
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

ul {
    list-style-type: none;
}

button:focus-visible {
    outline: thin var(--accent) dashed !important;
    outline-offset: var(--space-sm);
    color: var(--accent);
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}